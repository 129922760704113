@import '~antd/dist/antd.css';
@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-Medium';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-SemiBold';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-Bold';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

/* 공통 css */
html,
body,
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Pretendard';
}
li {
  list-style: none;
}
a,
button {
  cursor: pointer;
}
.wrap {
  max-width: 1240px;
  margin: 0 auto;
  padding: 100px 0;
}
.mobile {
  display: none !important;
}
.mobileImg {
  display: none !important;
}

@media (max-width: 1240px) {
  .wrap {
    padding: 100px 30px;
  }
}

@media (max-width: 768px) {
  .pc {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }
}

@media (max-width: 414px) {
  .wrap {
    padding: 30px 20px;
  }
  .pcImg {
    display: none !important;
  }
  .mobileImg {
    display: block !important;
  }
}
